/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Clear';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import SearchResultDropDown from '../../Header/components/Search/searchResultDropDown';
import ElasticSearchSuggestions from './ElasticSearchSuggestions/ElasticSearchSuggestions';

import customBreakpoints from '../../../helpers/customBreakpoints';
import { operations, selectors } from '../../../../state/ducks/App';
import { getFeatureFlags } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { actions as tagManagerActions } from '../../../../state/ducks/TagManager';
import { getCheckoutFeatureFlag } from '../../../../state/ducks/Checkout/ducks/App/App-Selectors';
import { getActiveABTests } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { getEnterpriseId } from '../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getEntryBrandCookie } from '../../../helpers/attribution/attributionHelpers';
import useBrowserUUID from '../../../helpers/useBrowserUUID';

const styles = (theme) => ({
    searchForm: {
        position: 'relative',
        display: 'flex',
        height: '39px',
        width: '100%',
        '& .MuiButtonBase-root': {
            color: theme.palette.cms?.white || '#ffffff',
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
            '&:focus': {
                outline: `1px solid  ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
                outlineColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
            },
            '&:hover': {
                backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
            },
        },
    },
    searchFormIe: {
        display: 'flex',
        width: '100%',
        height: '39px',
        position: 'relative',
    },
    searchFormLabel: {
        position: 'relative',
        [theme.breakpoints.down(1026)]: {
            width: '100%',
        },
    },
    ariaInvisible: {
        clip: 'rect(0 0 0 0)',
        height: '1px',
        overflow: 'hidden',
        position: 'absolute',
        width: '1px',
    },
    searchBoxLabel: {
        marginRight: '10px',
        position: 'relative',
    },
    searchBoxLabelHeaderRaised: {
        marginRight: '0',
        position: 'relative',
    },
    searchIcon: {
        position: 'absolute',
        top: '3px',
        left: '3px',
        color: theme.palette.grey4,
    },
    searchIconRaised: {
        position: 'relative',
        color: theme.palette.white,
        fontSize: '22px',
    },
    searchInput: {
        border: `1px solid ${theme.palette.grey8}`,
        color: theme.palette.grey4,
        fontFamily: 'arial,sans-serif',
        fontSize: '16px',
        textIndent: '10px',
        height: '37px',
        width: '100%',
        '&:focus': {
            outline: `1px solid ${theme.palette.colorNeutral30}`,
        },
        '&::placeholder': {
            color: theme.palette.black,
            fontSize: '14px',
            [theme.breakpoints.down('600')]: {
                fontSize: '10px',
            },
        },
    },
    wrappHomePage: {
        padding: '20px 10px 10px',
    },
    wrappDesktopHomePage: {
        width: '54%',
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            flexGrow: '1',
            margin: '0 3vw',
        },
    },
    wrappHeaderRaised: {
        padding: '0 10px 10px',
    },
    searchInputMenu: {
        borderRadius: '4px',
        textIndent: '0px',
    },
    searchInputHome: {
        borderRadius: '0',
        textIndent: '34px',
        height: '30px',
        border: '0',
        borderBottom: `1px solid ${theme.palette.grey8}`,
        fontFamily: theme.typography.fontFamily,
    },
    searchInputDesktopHome: {
        borderRadius: '3px 0 0 3px',
        width: '95%',
        height: '39px',
        border: `1px solid ${theme.palette.colorNeutral30}`,
        fontFamily: theme.typography.fontFamily,
        '&::placeholder': {
            color: theme.palette.colorNeutral70,
        },
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            width: '100%',
        },
    },
    searchInputHeaderRaised: {
        borderRadius: '4px 0 0 4px',
        textIndent: '4px',
        fontSize: 'inherit',
        height: '40px',
        color: theme.palette.black,
        fontFamily: theme.typography.fontFamily,
    },
    searchButton: {
        color: theme.palette.white,
        textTransform: 'uppercase',
        fontSize: '13px',
        backgroundColor: theme.palette.primaryButton,
        '-webkit-font-smoothing': 'antialiased',
        '&:hover': {
            backgroundColor: theme.palette.primaryButton,
        },
        '&:focus': {
            outline: `1px solid  ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
            outlineColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    searchButtonMenu: {
        minWidth: '80px',
        padding: '5px 14px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '700',
    },
    searchButtonHome: {
        minWidth: '100px',
        padding: '2px  14px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: '700',
        minHeight: '27px',

    },
    // Override the MFE MUI
    searchButtonDesktopHome: {
        minWidth: '100px !important',
        margin: '0 auto !important',
        padding: '2px  14px !important',
        textTransform: 'uppercase !important',
        fontSize: '12px !important',
        fontWeight: '700 !important',
        minHeight: '27px !important',
        borderRadius: '0 3px 3px 0 !important',
        backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '&:hover': {
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        [theme.breakpoints.down(customBreakpoints.tabLandscape)]: {
            minWidth: '50px',
            margin: '0',
        },
        [theme.breakpoints.up(customBreakpoints.tabLandscape)]: {
            minWidth: '100px',
        },
    },
    searchButtonDesktopHomeIe: {
        minWidth: '13%',
        margin: '0 auto',
        padding: '9px  14px',
        textTransform: 'uppercase',
        fontSize: '12px',
        fontWeight: '700',
        minHeight: '27px',
        borderRadius: '0 3px 3px 0',
        backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        '&:hover': {
            backgroundColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
        '&:focus': {
            outline: `1px solid  ${theme.palette.cms?.primary || theme.palette.colorPrimary}`,
            outlineColor: theme.palette.cms?.primary || theme.palette.colorPrimary,
        },
    },
    classSearchBoxDesktopLabel: {
        margin: '0',
        width: '87%',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    searchButtonHeaderRaised: {
        minWidth: '40px',
        minHeight: '40px',
        textTransform: 'none',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '0 2px 2px 0',
        backgroundColor: theme.palette.grey1,
        '&.MuiButtonBase-root': {
            borderRadius: '0 2px 2px 0',
            minWidth: '40px',
            fontWeight: 'bold',
            textTransform: 'none',
        },
    },
    hasValue: {
        fontSize: '14px',
    },
    progress: {
        margin: '4px',
        color: theme.palette.brown5,
    },
    modalContent: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.white,
        boxShadow: theme.shadows[5],
        padding: '10px',
        textAlign: 'center',
        borderRadius: '4px',
        '&:focus': {
            outline: `solid 1px ${theme.palette.cta1}`,
            boxShadow: `0 0 10px ${theme.palette.cta1}`,
        },
        '& .MuiButtonBase-root': {
            padding: '2px',
            position: 'absolute',
        },
    },
    descModal: {
        color: theme.palette.cta1,
        marginTop: '0px',
    },
    closeIconButton: {
        position: 'absolute',
        right: '-10px',
        top: '-10px',
        padding: '2px',
    },
    closeIcon: {
        backgroundColor: theme.palette.cta1,
        borderRadius: '100%',
        color: theme.palette.white,
        fontSize: '24px',
        padding: '1px',
    },
    resetSearchIcon: {
        padding: '4px',
        minWidth: '15px',
        maxWidth: '15px',
        height: '15px !important',
        position: 'absolute',
        right: '10px',
        color: theme.palette.white,
        fontSize: '12px',
        background: theme.palette.grey3,
        borderRadius: '50%',
        top: '10px',
    },
    resetSearchIconHide: {
        display: 'none !important',
    },
    resetSearchIconShow: {
        display: 'block',
        cursor: 'pointer',
    },
    searchPlaceholderError: {
        '&::placeholder': {
            color: theme.palette.error?.main || '#ff0000',
        },
    },
});

const ENTER_KEY = 13; // carriage-return
const PROMPT = 'Enter Keyword or Product Number';
const PROMPTRAISED = 'Search Keyword or Product Number';
const PROMPTHOME = 'What are you looking for?';
const MODALMESSAGE = 'Please enter a keyword or Product Number to search.';

// Props:
//    languageId    id for language (-1 for English)
//    brand         object representing brand currently displaying for user.  Fields used include:
//                      storeId, catalogId, code
export class Search extends Component {
    constructor(props) {
        super(props);

        const type = props.typeSearchBox;
        let searchPlaceholder = PROMPT;
        let currentPrompt = PROMPT;
        this.searchBrands = ['HD', 'SY', 'WLF'];
        if (props.searchInputTerm) {
            searchPlaceholder = props.searchInputTerm.replace('+', ' ');
        } else if (type === 'homepage') {
            searchPlaceholder = PROMPTHOME;
            currentPrompt = PROMPTHOME;
        }

        if (this.searchBrands.includes(props.brand.code)) {
            searchPlaceholder = PROMPTRAISED;
            currentPrompt = PROMPTRAISED;
        }

        this.state = {
            loading: false,
            searchPlaceholderValue: searchPlaceholder,
            searchValue: '',
            currentPrompt,
            showClearIcon: false,
            ieBrowser: false,
            showInputValue: false,
        };

        this.searchRef = createRef();
        this.searchContainerRef = createRef();
        this.handleClickOutsideSearch = this.handleClickOutsideSearch.bind(this);
        const { featureFlags } = this.props;
        this.esAutoCompleteEnabled = featureFlags['is-elastic-auto-complete-enabled'];
        this.isExperimentsServiceEnabled = featureFlags['is-experiments-service-enabled']?.autoSuggest;
        this.targetingEnabled = featureFlags['is-graphql-targeting-enabled'];
        this.targeting = [];
        this.isClient = typeof window !== 'undefined';
        if (!featureFlags['is-elastic-search-enabled']) {
            // disabling ES auto complete if ES is disabled.
            this.esAutoCompleteEnabled = false;
        }
        if (this.isClient && featureFlags['is-experiments-service-enabled']?.autoSuggest) {
            const browserUUID = (this.isClient && window.localStorage) ? useBrowserUUID() : '';
            const pageURL = (this.isClient && window.location) ?  window.location.href : '';
            // do not change position, values are being changed based on element position below
            this.targeting = [{
                key: 'EID', value: this.props.enterpriseId,
            }, {
                key: 'browserUUID', value: browserUUID,
            }, {
                key: 'entryBrand', value: getEntryBrandCookie() || props?.brand?.domain || '',
            }, {
                key: 'pageType', value: 'autosuggest',
            }, {
                key: 'pageURL', value: pageURL,
            }];
        }
    }

    componentDidMount() {
        const {
            clearSearchResult, focusOnShow, location, history,
        } = this.props;

        if (typeof document !== 'undefined') {
            const ieBrowser = /* @cc_on!@ */false || !!document.documentMode;
            this.setState({ ieBrowser });
            clearSearchResult({});
            if (focusOnShow && this.searchRef?.current) {
                this.searchRef.current.focus();
            }
            document.addEventListener('mousedown', this.handleClickOutsideSearch);
        }
        // below if condition added for Fixing PZ-4782 & PZ-4783 issues related to clickstream product impression handled through redirection to notfound page
        if (location?.pathname?.split('/').indexOf('searchterm') >= 0 && location.pathname.split('/').filter((n) => n).length === 1) {
            this.setState(() => {
                history.push({
                    pathname: 'notfound',
                    state: { searchInputTerm: 'notfound' },
                });
            });
        }
    }

    componentWillUnmount() {
        if (typeof document !== 'undefined') {
            document.removeEventListener('mousedown', this.handleClickOutsideSearch);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchPlaceholderValue } = prevState;
        if (searchPlaceholderValue === MODALMESSAGE) {
            this.setState({ searchPlaceholderValue: PROMPT });
        }
        if (this.props.enterpriseId !== prevProps.enterpriseId) {
            this.targeting[0] = {
                key: 'EID', value: this.props.enterpriseId,
            };
        }
    }

    onChange = (event) => {
        const { getSearchResult, clearSearchResult } = this.props;
        this.setState({ searchValue: this.cleanValue(event.target.value) });
        getSearchResult(event.target.value);
        if (!event.target.value) {
            clearSearchResult({});
        }
    };

    onFocus = () => {
        const { brand } = this.props;
        const { searchPlaceholderValue, currentPrompt } = this.state;

        if (searchPlaceholderValue === currentPrompt) {
            if (this.searchBrands.includes(brand.code)) {
                this.setState({ searchPlaceholderValue: PROMPTRAISED });
            } else {
                this.setState({ searchPlaceholderValue: PROMPTHOME });
            }
        }
        if (searchPlaceholderValue !== '' && searchPlaceholderValue !== currentPrompt) {
            this.setState({ showClearIcon: true });
        }
    };

    onBlur = () => {
        const { currentPrompt } = this.state;

        this.setState((prevState) => {
            if (prevState.searchPlaceholderValue.trim() === '') {
                return { searchPlaceholderValue: currentPrompt };
            }
            return {};
        });
    };

    onKeyPress = (event) => {
        if (
            (event.which && event.which === ENTER_KEY)
            || (event.keyCode && event.keyCode === ENTER_KEY)
        ) {
            this.submitSearchForm(event);
        }
    };

    onKeyUp = () => {
        const { searchValue } = this.state;

        if (searchValue.trim() !== '') {
            this.setState({ showClearIcon: true });
        } else {
            this.setState({ showClearIcon: false });
        }
    };

    cleanValue = (v, maxLength = 100) => {
        // filter out control characters to help prevent XSS ...
        const cleanVal = v.replace(/[\x00-\x1F\x7F-\x9F]/g, ''); /* eslint no-control-regex: "off" */
        // ... and don't allow value to exceed maxLength
        return cleanVal.length <= maxLength ? cleanVal : cleanVal.substr(0, maxLength);
    };

    cleanTerm = (input) => {
        // removes extra spaces an ., example input +_)(*()()_    roses . +_)()_)))() red +_)()_))

        const a = input.replace(/[`~+!@#$%^&*()_|=?;:'",.<>{}/\\]/g, ''); // replaces all special chars with spaces
        const b = a.replace(/\s+/g, ' '); // removes extra consecutive spaces with just one space
        const c = b.replace(/^\s+|\s+$/g, ''); // removes spaces in the beginning and end of search term
        // output would be "roses+red" for the url
        return c;
    }

    concatSearchTerm = (url, value) => {
        let searchTerm = '';
        if (url.indexOf('?') >= 0) {
            searchTerm = `&searchTerm=${this.cleanTerm(value)?.trim().replace(/ /g, '_').toLowerCase()}`; // replaces spaces between words with _
        } else if (url.indexOf('searchTerm=') >= 0) {
            searchTerm = '';
        } else {
            searchTerm = `?searchTerm=${this.cleanTerm(value)?.trim().replace(/ /g, '_').toLowerCase()}`; // replaces spaces between words with _
        }
        return `${url}${searchTerm}`;
    }

    submitSearchForm = (evt) => {
        const {
            pageLoaded, brand, trackEvent, trackClickThrough, featureFlags,
        } = this.props;
        const { currentPrompt, searchValue } = this.state;
        if (typeof document !== 'undefined') {
            let searchInput = null;
            evt.preventDefault();
            const { history, typeSearchBox } = this.props;
            const searchBox = document.getElementById(`SearchBox_${typeSearchBox}`);
            if (
                searchBox
                && (searchBox.value !== PROMPT
                    || searchBox.value !== PROMPTHOME
                    || searchBox.value !== PROMPTRAISED)
            ) {
                searchInput = searchBox.value !== '' ? searchBox.value : null;
            }

            const addSearchTerm = brand['search-append-searchterm-url-param'] || false;

            // clickstream ab test track
            if (featureFlags['is-search-ui-in-mobile-header-enabled']) { trackClickThrough(); }

            trackEvent({
                eventCategory: 'Search',
                eventAction: searchValue,
                eventLabel: 'MANUAL | <<pageType>>',
                searchVariety: 'Manual',
                searchPhrase: searchValue,
                eventName: 'search',
            });

            if (searchInput && searchInput.trim() && searchInput !== currentPrompt) {
                const cleanSearchInput = this.cleanTerm(searchInput);
                let searchTermValue = cleanSearchInput
                    .trim()
                    .replace(/ /g, '+') // replaces spaces between words with +
                    .toLowerCase();
                if (searchTermValue.length !== 0 && addSearchTerm) {
                    searchTermValue = this.concatSearchTerm(searchTermValue, cleanSearchInput);
                }
                this.setState({ loading: true }, () => {
                    history.push({
                        pathname: `/searchterm/${searchTermValue.length === 0 ? 'notfound' : searchTermValue}`,
                        state: { searchInputTerm: searchValue || '' },
                    });
                });
                searchBox.blur();
                this.clearSearch(false);
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false });
            }
            if (!searchInput) {
                this.setState({ searchPlaceholderValue: MODALMESSAGE });
            }
        }
        if (pageLoaded !== '' && this.searchBrands.includes(brand.code)) {
            this.setState({ loading: false });
        }
    };

    clearSearch = (persistValue = false, queryString = null) => {
        const { searchButtonClick, trackEvent } = this.props;
        const { searchValue } = this.state;
        const clear = {
            showClearIcon: false,
            showInputValue: false,
        };
        if (!persistValue) {
            clear.searchPlaceholderValue = PROMPTRAISED;
            clear.searchValue = '';
        } else {
            clear.showInputValue = false;
            if (queryString) {
                clear.searchValue = '';
                trackEvent({
                    eventCategory: 'Search',
                    eventLabel: queryString,
                    eventAction: 'SUGGESTED | <<pageType>>',
                    searchVariety: 'Suggested',
                    searchPhrase: searchValue,
                    eventName: 'search',
                });
            }
        }
        this.setState(clear);
        searchButtonClick();
    };

    handleClickOutsideSearch(event) {
        const { showClearIcon } = this.state;
        if (this.searchContainerRef && event?.target && this.searchContainerRef?.current && !this.searchContainerRef.current.contains(event.target) && showClearIcon) {
            this.setState({
                showClearIcon: false,
            });
        }
    }

    renderButtonSearch = (classSearchButton) => (
        <Button className={classSearchButton} id="btn-search" data-test="hp-header-search-button" name="hpSearchButton" onClick={this.submitSearchForm} type="submit">
            Search
        </Button>
    );

    render() {
        const {
            classes, typeSearchBox, brand, suggestData, featureFlags, trackEvent,
            ffWhichCheckoutVariation, activeABTests,
        } = this.props;
        const {
            searchValue,
            currentPrompt,
            loading,
            searchPlaceholderValue,
            showClearIcon,
            ieBrowser,
            showInputValue,
        } = this.state;
        // Adding the class according of the typeSearchBox.
        // typeSearchBox 'menu', 'homepage', 'header' (header is for HD) or empty
        let classSearchBoxLabel = classes.searchBoxLabel;
        let classSearchInput = [classes.searchInput];
        let classSearchButton = [classes.searchButton];
        let iconSearch = null;
        let classWrapper = null;
        switch (typeSearchBox) {
            case 'menu':
                classSearchInput.push(classes.searchInputMenu);
                classSearchButton.push(classes.searchButtonMenu);
                break;
            case 'desktop':
                classSearchInput.push(classes.searchInputDesktopHome);
                classSearchButton.push(ieBrowser ? classes.searchButtonDesktopHomeIe : classes.searchButtonDesktopHome);
                classSearchBoxLabel = classes.classSearchBoxDesktopLabel;
                classWrapper = classes.wrappDesktopHomePage;
                break;
            case 'homepage':
                classSearchInput.push(classes.searchInputHome);
                classSearchButton.push(classes.searchButtonHome);
                iconSearch = <SearchIcon className={classes.searchIcon} style={{ maxWidth: '40px' }} />;
                classWrapper = classes.wrappHomePage;
                break;
            case 'header':
                classSearchBoxLabel = classes.classSearchBoxLabelHeaderRaised;
                classSearchInput.push(classes.searchInputHeaderRaised);
                classSearchButton.push(classes.searchButtonHeaderRaised);
                classWrapper = classes.wrappHeaderRaised;
                break;
            case 'category':
                break;
            default:
                classSearchInput = [classes.searchInput];
                classSearchButton = [classes.searchButton];
                break;
        }

        // Add the class hasValue.
        if (
            searchValue !== undefined
            && searchValue !== null
            && searchValue !== ''
            && searchValue !== currentPrompt
        ) {
            classSearchInput.push(classes.hasValue);
        }

        classSearchInput = classSearchInput.join(' ');
        classSearchButton = classSearchButton.join(' ');
        const actionForm = `/searchterm/${this.cleanTerm(searchValue)}`;
        const searchPlaceholderCss = searchPlaceholderValue === MODALMESSAGE ? classes.searchPlaceholderError : '';
        if (typeof window !== 'undefined') {
            // Avoid displaying if page is checkout
            if (ffWhichCheckoutVariation === 'floral' && activeABTests?.checkout !== 'universal' && window.location.href.split('/').indexOf('checkout') >= 0) {
                return null;
            } if (ffWhichCheckoutVariation === 'food' && activeABTests?.checkout !== 'universal' && window.location.href.split('/').indexOf('checkout') >= 0 && window.location.href.split('/').indexOf('order-confirmation') < 0) {
                return null;
            }
        }

        return (
            <div ref={this.searchContainerRef} className={classWrapper}>
                <form
                    id="CatalogSearchForm"
                    name="CatalogSearchForm"
                    action={actionForm}
                    method="get"
                    onSubmit={this.submitSearchForm}
                    className={ieBrowser ? classes.searchFormIe : classes.searchForm}
                >
                    <label
                        className={`${classSearchBoxLabel} ${classes.searchFormLabel}`}
                        htmlFor={`SearchBox_${typeSearchBox}`}
                    >
                        {iconSearch}
                        <input
                            id={`SearchBox_${typeSearchBox}`}
                            name={`searchTerm_${typeSearchBox}`}
                            className={`${classSearchInput} ${searchPlaceholderCss}`}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onChange={this.onChange}
                            onKeyPress={this.onKeyPress}
                            onKeyUp={this.onKeyUp}
                            autoComplete="off"
                            value={searchValue}
                            type="text"
                            readOnly={loading}
                            placeholder={searchPlaceholderValue}
                            ref={this.searchRef}
                            data-test="header-search-box" // do NOT change or remove data-test attribute
                            data-testid="header-search-box"
                        />
                        <span className={classes.ariaInvisible}>Search Box</span>
                        {this.searchBrands.includes(brand.code) ? (
                            <CloseIcon
                                onClick={() => this.clearSearch()}
                                className={`close-icon ${classes.resetSearchIcon} ${classes.closeIcon} ${(showClearIcon || showInputValue) ? classes.resetSearchIconShow : classes.resetSearchIconHide
                                    }`}
                            />
                        ) : (
                            ''
                        )}
                    </label>
                    {/* BLOOMREACH SEARCH */}
                    {Object.entries(suggestData).length > 0
                        && featureFlags
                        && !this.esAutoCompleteEnabled
                        && !featureFlags['is-elastic-search-enabled']
                        && showClearIcon
                        && (
                            <SearchResultDropDown
                                suggest={suggestData}
                                clearFunc={this.clearSearch}
                                showClearIcon={showClearIcon}
                                track={trackEvent}
                                phrase={searchValue}
                                concatSearchTerm={this.concatSearchTerm}
                            />
                        )}

                    {/* ELASTIC SEARCH */}
                    {this.esAutoCompleteEnabled && showClearIcon && searchValue && <ElasticSearchSuggestions cleanTerm={this.cleanTerm} concatSearchTerm={this.concatSearchTerm} track={trackEvent} brand={brand.domain} clearSearch={this.clearSearch} phrase={searchValue} targeting={this.targeting} experimentsService={Boolean(this.isExperimentsServiceEnabled && this.isClient)} targetingEnabled={this.targetingEnabled} />}

                    {!loading && this.renderButtonSearch(classSearchButton)}
                    {loading && (
                        <div className="loading-btn" id="searchBtnLoading">
                            <CircularProgress className={classes.progress} size={30} thickness={6} />
                        </div>
                    )}
                </form>
            </div>
        );
    }
}

Search.propTypes = {
    typeSearchBox: PropTypes.string,
    searchInputTerm: PropTypes.string,
    brand: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    pageLoaded: PropTypes.string,
    history: PropTypes.object.isRequired,
    searchButtonClick: PropTypes.func,
    getSearchResult: PropTypes.func.isRequired,
    suggestData: PropTypes.object,
    featureFlags: PropTypes.object,
    clearSearchResult: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
    focusOnShow: PropTypes.bool,
    ffWhichCheckoutVariation: PropTypes.string,
    activeABTests: PropTypes.shape({
        checkout: PropTypes.string,
    }).isRequired,
    location: PropTypes.object,
    enterpriseId: PropTypes.string,
    trackClickThrough: PropTypes.func,
};

Search.defaultProps = {
    typeSearchBox: null,
    searchInputTerm: '',
    pageLoaded: '',
    suggestData: {},
    featureFlags: {},
    focusOnShow: false,
    searchButtonClick: () => {},
    ffWhichCheckoutVariation: '',
    location: {},
    enterpriseId: '',
    trackClickThrough: () => {},
};

const mapStateToProps = (state) => {
    const { ui } = state;
    return {
        pageLoaded: ui.pageFullyLoaded,
        suggestData: selectors.getSuggestionData(state),
        featureFlags: getFeatureFlags(state),
        ffWhichCheckoutVariation: getCheckoutFeatureFlag('which-checkout-variation')(state),
        activeABTests: getActiveABTests(state),
        enterpriseId: getEnterpriseId(state),
    };
};

const mapDispatchToProps = {
    getSearchResult: operations.getSearchResult,
    clearSearchResult: operations.clearSearchResult,
    trackEvent: tagManagerActions.trackEvent,
};

export default withRouter(
    withStyles(styles, { withTheme: true })(
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )(Search),
    ),
);
